<template>
  <section class="line-echart">
    <div ref="stackedLine" style="width: 100%; height: 100%"></div>
  </section>
</template>

<script>
/**
 * 折线图
 */
import * as echarts from "echarts/core";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([
  GridComponent,
  TooltipComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  TitleComponent,
]);

export default {
  name: "StackedLine",
  props: {
    titleData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    xData: {
      type: Array,
      default: () => ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yData: {
      type: Array,
      default: () => [820, 932, 901, 934, 1290, 1330, 1320],
    },
    lineColor: {
      type: String,
      default: "#1D8DB9",
    },
  },
  data: () => ({
    myChart: null,
  }),
  watch: {
    xData(val) {
      if (val) {
        val && this.myChart && this.myChart.setOption(this.option);
      }
    },
    yData(val) {
      if (val) {
        val && this.myChart && this.myChart.setOption(this.option);
      }
    },
  },
  computed: {
    option() {
      return {
        title: {
          text: this.titleData.text || "",
          textStyle: this.titleData.textStyle || {},
        },
        color: [this.lineColor],
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.xData || [],
          axisLine: {
            show: true,
            lineStyle: {
              color: "#C9C9CB",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: "#000000",
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#C9C9CB",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: "#000000",
          },
        },
        series: [
          {
            data: this.yData || [],
            type: "line",
            symbol: "circle",
            symbolSize: 0,
            smooth: false,
          },
        ],
      };
    },
  },
  methods: {
    initChart() {
      this.myChart = echarts.init(this.$refs["stackedLine"]);
      this.myChart.setOption(this.option);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });

    window.addEventListener("resize", () => {
      this.$nextTick(() => {
        this.myChart && this.myChart.resize();
      });
    });
  },
  beforeDestroy() {
    if (this.myChart) {
      this.myChart.dispose();
    }
  },
};
</script>

<style lang="scss" scoped>
.line-echart {
  width: 100%;
  height: 100%;
}
</style>
