import * as API from "@/api/request";

const comUrl = "/v1/workbench/asset/";

export function assetTotal() {
  return API.get(`${comUrl}total`);
}

export function todayFlow() {
  return API.get(`${comUrl}tx/today-flow`);
}

export function recentFlow() {
  return API.get(`${comUrl}tx/recent-flow`);
}

